
import { defineComponent, ref } from 'vue';
import { VisitorFormData, MotionTimeOptionsData } from '@/views/pm/setting/all.type';
import { getMotionTimeOptions, getEnableMotionOptions } from '@/views/pm/setting/util';
import { setting, community } from '@/data';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    components: {},
    setup() {
        const formRef = ref<any>();
        const formData = ref<VisitorFormData>({
            IDCardVerification: 0,
            FaceEnrollment: 0
        });

        const enableMotionOptions: Array<string> = getEnableMotionOptions();
        const motionTimeOptions: Array<MotionTimeOptionsData> = getMotionTimeOptions();

        setting.getPMCommunityDetail((res: {
            IDCardVerification: '0' | '1';
            FaceEnrollment: '0' | '1';
        }) => {
            Object.keys(formData.value).forEach((item) => {
                const key = item as keyof VisitorFormData;
                formData.value[key] = Number(res[key]) as 0 | 1;
            });
        });

        const submit = () => {
            HttpRequest.post(`v3/web/${community.ProjectType.value}/${community.ProjectType.value}Data/setVisitor`, formData.value, () => {
                //
            });
        };

        return {
            formRef,
            formData,
            enableMotionOptions,
            motionTimeOptions,
            submit
        };
    }
});
